import React from "react"
import {
  Sponsoren,
  Facebook,
  Hero,
  Tiles,
  Doelen,
  WieZijnWij,
  Layout,
} from "../components"
import { GatsbySeo, LocalBusinessJsonLd } from "gatsby-plugin-next-seo"

const Home = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Homepagina"
        description="Homepagina van zwembadraad blijven spetteren"
      />
      <LocalBusinessJsonLd
        type="PublicSwimmingPool"
        id="https://www.blijvenspetteren.nl/"
        name="Zwembadraad Blijven Spetteren"
        description="Website Zwembadraad Blijven Spetteren"
        url="https://www.blijvenspetteren.nl/"
        telephone="0166-602862"
        address={{
          streetAddress: "p/a Burg. Waghtoplein 4",
          addressLocality: "Tholen",
          postalCode: "4691 JH",
          addressCountry: "NL",
        }}
        geo={{
          latitude: "51.5381384",
          longitude: "4.2172299",
        }}
        images={[
          "https://www.blijvenspetteren.nl/logo_blijvenspetteren_small.png",
        ]}
      />
      <Hero />
      <Tiles />
      <Doelen />
      <Sponsoren />
      <WieZijnWij />
      <Facebook />
    </Layout>
  )
}

export default Home
